import { AppointmentType, CandidateAudit, Question } from '../../../../../firebase/firestore/documents/candidateAudit';
import { CandidateAuditActionNames } from '../../../../../firebase/firestore/documents/candidateAuditActionNames';
import { Requisition } from '../../../../../firebase/firestore/documents/requisition';
import { ResubmissionRequestFields } from '../../../../../firebase/firestore/documents/candidate';

export class HistoryPresenter {
  readonly id: string = this.message.id;

  readonly userId: string = this.message.userId;

  readonly content: string = this.message.message;

  readonly action: CandidateAuditActionNames = this.message.action;

  readonly actionCategory: string = this.message.action;

  readonly automation?: boolean = this.message.automation;

  readonly timestamp: Date = this.message.timestamp;

  readonly screeningOptions: { [key: string]: boolean } = this.message.screeningOptions;

  readonly appointmentType?: AppointmentType = this.message.appointment?.appointmentType;

  readonly question?: Question = this.message.question;

  readonly appointmentDate?: Date = this.message.appointment?.date;

  readonly recruiterFirstName?: string = this.message.recruiterFirstName;

  readonly userFullName?: string = this.message.userFullName;

  readonly businessName?: string = this.message.businessName;

  readonly positionName?: string = this.message.positionName;

  readonly requisitionId?: string = this.message.requisitionId;

  readonly requisition?: Requisition = this.message.requisition;

  readonly positionId?: string = this.message.positionId;

  readonly candidateFullName?: string = this.message.candidateFullName;

  readonly interestRevokeReason?: string = this.message.reasonId;

  readonly interestRevokeReasonComment?: string = this.message.reasonComment;

  readonly rejectedReason?: string = this.message.message;

  readonly rejectedReasonId?: string = this.message.reasonId;

  readonly reasonComment?: string = this.message.reasonComment;

  readonly expectedWorkHour?: { unit: string; value: string } = this.message.expectedWorkHour;

  readonly offeredSalary?: { salary: string; salaryUnit: string } = this.message.offeredSalary;

  readonly availability?: { [key: string]: { start_1: string; end_1: string } } = this.message?.availability;

  readonly nightAvailabilities?: { [key: string]: { start_1: string; end_1: string } } = this.message?.nightAvailabilities;

  readonly shiftIntersection?: { [key: string]: { start_1: string; end_1: string } } = this.message?.shiftIntersection;

  readonly hireDetailsResubmissionRequest?: ResubmissionRequestFields[] = this.message.hireDetailsResubmissionRequest;

  constructor(private readonly message: CandidateAudit) {}
}
