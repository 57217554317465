/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _take from 'lodash/take';
import _isNil from 'lodash/isNil';
import { Box, Center, Flex, IconButton, Image, Spacer, Spinner, Stack, Text, Tooltip } from '@chakra-ui/core';
import { useParams } from 'react-router';
import TreeMenu, { TreeMenuItem } from 'react-simple-tree-menu';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { BsFunnel } from 'react-icons/bs';
import _map from 'lodash/map';
import SeekerListFilters from '../filters/SeekerListFilters';
import { useSeekersFilters } from '../filters/SeekersFiltersContext';
import { CandidateItemNode, NodeType, ParentItemNode, TreeGroup } from './treeGroup';
import { seekersListContainer, seekersListItems } from './SeekerListContainer.styles';
import { ParentItem } from './parentItem/ParentItem';
import { ChildItem } from './childItem/ChildItem';
import { CandidateItem } from './candidateItem/CandidateItem';
import { MESSAGING_TABS, MessagingTabsParams, MESSAGING_CANDIDATE_TABS } from '../../../../routes/constants';
import {
  CandidateCategory,
  CandidateStatus,
  EngagedCandidate,
  FitStatus,
  RecordingStatus,
} from '../../../../firebase/firestore/documents/candidate';
import { SeekersPresenter } from './seekersPresenter';
import { useMessagingViewContext } from '../../MessagingViewContext';
import { SeekerContainerTabItems } from '../../seeker/SeekerContainerContext';
import SeekerListGroupType from '../filters/seekerListGroupType';
import { getConfig } from './seekerListContainerConfig';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { seekersListFilters } from '../filters/SeekerListFilters.styles';
import colors from '../../../../styles/colors';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';
import { useCurrentUserProfile } from '../../../../app/hooks/useCurrentUserProfile';
import { FavouriteSeeker } from '../../../../firebase/firestore/documents/user';
import { useUserDocumentSnapshot } from '../../../../firebase/firestore/hooks';
import { PositionWiseSearchModal } from '../searchAndSort/PositionWiseSearchModal';
import { LocalizedString } from '../../../../firebase/firestore/documents/localizedString';
import { CandidateListType } from '../filters/seekersFiltersContextValueType';
import { LanguageEvaluationStatus } from '../searchAndSort/LanguageEvaluation';
import useCandidate from '../../../../app/components/useCandidate';
import { NotAuthorizedView } from '../../../administration/NotAuthorizedView';
import { isPositionCandidatesPage } from '../../../../utils/candidatesUtils';

const MotionBox = motion.custom(Box);

type ToggleIconProp = {
  on: boolean;
};

const addBoxClass = (item: TreeMenuItem) => {
  switch (item.type) {
    case NodeType.PARENT:
      return 'parent-block';
    case NodeType.CHILD:
      return 'child-block';
    default:
      return 'candidate-block';
  }
};

const ToggleIcon = ({ on }: ToggleIconProp) => (
  <span>{on ? <ChevronUpIcon w={6} h={6} /> : <ChevronDownIcon w={6} h={6} />}</span>
);

export type SeekerListContainerProps = {
  showSidebar: boolean;
  isCertnFeatureEnabled: boolean;
  isCertnEnabled: boolean;
  isUnresponsiveCandidatesEnabled: boolean;
  isArchiveListEnabled: boolean;
  isDismissedCandidateEnabled: boolean;
  onOpenSearch: () => void;
  showSearchSort: boolean;
  shouldRedirect?: boolean;
  givenCandidateId?: string;
  seekerListFromProps?: SeekersPresenter | undefined;
};

export const SeekerListContainer = ({
  showSidebar,
  isCertnFeatureEnabled,
  isCertnEnabled,
  isArchiveListEnabled,
  isDismissedCandidateEnabled,
  isUnresponsiveCandidatesEnabled,
  onOpenSearch,
  showSearchSort,
  shouldRedirect,
  givenCandidateId,
  seekerListFromProps,
}: SeekerListContainerProps): JSX.Element => {
  const { i18n } = useTranslation();
  const { t } = useTranslation('noSeekerMsg');
  const allSeekerList: SeekersPresenter | undefined = useStoreState((s) => s.candidate.allCandidates);
  const seekerList = seekerListFromProps || allSeekerList;
  const allPositionSeekerList: SeekersPresenter | undefined = useStoreState((s) => s.candidate.allPositionCandidates);
  const minutesBeforeInactivity = useStoreState((state) => state.remoteConfig[RemoteConfigKey.MINUTES_BEFORE_INACTIVITY]);

  const [seekers, setSeekers] = useState<TreeGroup[]>([]);
  const [visibleSeekerCount, setVisibleSeekerCount] = useState(getConfig().defaultVisibleSeekerCount);
  const [filteredSeekerByPosition, setFilteredSeekerByPosition] = useState<string[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<TreeMenuItem | undefined>(undefined);

  const { candidateId: candidateIdFromParams } = useParams<MessagingTabsParams>();
  const candidateId = givenCandidateId || candidateIdFromParams;
  const { currentUserProfile } = useCurrentUserProfile();
  const user = useUserDocumentSnapshot(currentUserProfile?.id);

  const {
    selectedEngagedCandidate,
    setSelectedEngagedCandidate,
    setSelectedEngagedSeeker,
    selectedEngagedSeeker,
  } = useMessagingViewContext();

  const isPositionSearchEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_POSITION_SEARCH];
  });

  const {
    sort,
    group,
    searchQuery,
    ghostedListView,
    favoriteCandidateView,
    isFromSearchAndSort,
    searchAndSortTypes,
    archiveListView,
    dismissListView,
    setIsPositionFilterApplied,
    isPositionFilterApplied,
    setSelectedPositionIdForFilter,
    openFilterByPositionList,
    listType,
    changeCandidate,
  } = useSeekersFilters();

  const isShowFavoriteStatusEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.SHOW_FAVORITE_STATUS];
  });

  const selectedCallback = useCallback(
    (candidate?: Readonly<EngagedCandidate>) => {
      setSelectedEngagedCandidate(candidate);
      setSelectedEngagedSeeker(candidate && { seekerId: candidate.seeker, candidateId: candidate.id });
      if (candidate && !isPositionCandidatesPage()) {
        // istanbul ignore else
        if (shouldRedirect) {
          window.history.pushState(null, '', MESSAGING_TABS(candidate.id, SeekerContainerTabItems.Messaging));
        } else {
          window.history.pushState(null, '', MESSAGING_CANDIDATE_TABS(candidate.id, SeekerContainerTabItems.Messaging));
        }
      }
    },
    [setSelectedEngagedCandidate, setSelectedEngagedSeeker, shouldRedirect],
  );

  useMemo(() => {
    setSeekers([]);
    if (seekerList) {
      let mappedSeekers = seekerList.getSeekersList(i18n.language, sort, searchQuery, group);
      if (ghostedListView && currentUserProfile) {
        mappedSeekers = seekerList.getGhostedSeekersList(i18n.language, sort, searchQuery, group);

        // const agedMappedSeekers = seekerList.getAgedSeekersList(i18n.language, sort, searchQuery, group, unresponsiveAgedPeriod);
        // setAgedSeekers(agedMappedSeekers);
      } else if (archiveListView) {
        mappedSeekers = seekerList.getArchivedSeekersList(i18n.language, sort, searchQuery, group);
      } else if (dismissListView) {
        mappedSeekers = seekerList.getDismissedSeekersList(i18n.language, sort, searchQuery, group);
        mappedSeekers = seekerList.getDismissedSeekersList(i18n.language, sort, searchQuery, group);
      } else if (favoriteCandidateView && currentUserProfile) {
        let newmappedSeekers = seekerList.engagedCandidates;
        newmappedSeekers = newmappedSeekers.filter((f) =>
          user?.favouriteSeekers?.some((item: FavouriteSeeker) => item.id === f.seeker),
        );
        const seekerPresenter = new SeekersPresenter(newmappedSeekers, currentUserProfile.id, minutesBeforeInactivity);
        mappedSeekers = seekerPresenter.getSeekersList(i18n.language, sort, searchQuery, group);
      } else if (isFromSearchAndSort && currentUserProfile) {
        let newmappedSeekers = seekerList.engagedCandidates;
        if (searchAndSortTypes.isFavouriteApplied) {
          newmappedSeekers = newmappedSeekers.filter((f) =>
            user?.favouriteSeekers?.some((item: FavouriteSeeker) => item.id === f.seeker),
          );
        }
        if (searchAndSortTypes.backgroundCheck.length > 0) {
          const validBackgroundCheckStatus = new Set(searchAndSortTypes.backgroundCheck);

          newmappedSeekers = newmappedSeekers.filter((value) => {
            const { screeningDetails } = value;
            return screeningDetails && validBackgroundCheckStatus.has(screeningDetails.status);
          });
        }

        if (searchAndSortTypes.hireStatus.length > 0) {
          const validHireStatus = new Set(searchAndSortTypes.hireStatus.map((status) => status.toLowerCase()));

          newmappedSeekers = newmappedSeekers.filter((value) => {
            const hireStatus = value.hireDetailsStatus;
            return hireStatus && validHireStatus.has(hireStatus.toLowerCase());
          });
        }
        /* istanbul ignore next */
        if (searchAndSortTypes.languageEvaluation.length > 0) {
          const langEvaluations = new Set(searchAndSortTypes.languageEvaluation.map((lang) => lang.toLowerCase()));
          newmappedSeekers = newmappedSeekers.filter((value) => {
            const languageEvalReview = value.languageEvaluationReview;
            if (langEvaluations && langEvaluations.has(LanguageEvaluationStatus.TO_BE_ACCESSED.toLowerCase())) {
              if (value.recording && !languageEvalReview) {
                return value;
              }
            }
            if (langEvaluations && langEvaluations.has(LanguageEvaluationStatus.REQUESTED.toLowerCase())) {
              if (
                value.recordingStatus &&
                (value.recordingStatus === RecordingStatus.RE_REQUESTED || value.recordingStatus === RecordingStatus.REQUESTED)
              ) {
                return value;
              }
            }
            return languageEvalReview && langEvaluations.has(languageEvalReview?.rating?.toLowerCase());
          });
        }

        if (searchAndSortTypes.kioskEnabled) {
          newmappedSeekers = newmappedSeekers.filter((f) => f.kioskEnabled);
        }
        if (searchAndSortTypes.isPriorityCandidates) {
          newmappedSeekers = newmappedSeekers.filter((f) => f.priority);
        }
        if (searchAndSortTypes.isInterventionEnabled) {
          newmappedSeekers = newmappedSeekers.filter((f) => f.interventionEnabled);
        }

        /* istanbul ignore next */
        if (searchAndSortTypes.requisitionList.length > 0 && searchAndSortTypes.availabilityFit.length > 0) {
          /* eslint-disable array-callback-return */
          // eslint-disable-next-line consistent-return
          newmappedSeekers = newmappedSeekers.filter((value) => {
            const filteredRequisitions = value?.availabilityMatchedRequisitions?.filter((requisition) =>
              searchAndSortTypes.requisitionList.includes(requisition.requisitionId),
            );

            if (filteredRequisitions && filteredRequisitions.length > 0) {
              const hasMatchingFit = filteredRequisitions?.some((requisition) =>
                searchAndSortTypes.availabilityFit.includes(requisition?.fit?.toLowerCase() as string),
              );
              return hasMatchingFit;
            }
          });
        }

        /* istanbul ignore next */
        if (searchAndSortTypes.requisitionList.length > 0) {
          /* eslint-disable array-callback-return */
          // eslint-disable-next-line consistent-return
          newmappedSeekers = newmappedSeekers.filter((value) => {
            const foundAvailabilityMatchedRequisitionsIdList =
              value?.availabilityMatchedRequisitions?.map((el) => el.requisitionId) || [];
            if (value.availabilityMatchedRequisitions && value.availabilityMatchedRequisitions.length > 0) {
              return searchAndSortTypes.requisitionList.some((requisition) =>
                foundAvailabilityMatchedRequisitionsIdList.includes(requisition),
              );
            }
          });
        }

        if (searchAndSortTypes.availabilityFit.length > 0) {
          /* eslint-disable array-callback-return */
          // eslint-disable-next-line consistent-return
          newmappedSeekers = newmappedSeekers.filter((value) => {
            const foundAvailabilityMatchedRequisitionsFitList =
              value?.availabilityMatchedRequisitions?.map((el) => el.fit?.toLowerCase()) || /* istanbul ignore next */ [];

            /* istanbul ignore next */
            if (value.availabilityMatchedRequisitions && value.availabilityMatchedRequisitions.length > 0) {
              if (value.availabilityMatchedRequisitions.length > 0) {
                return searchAndSortTypes.availabilityFit.some((fit) =>
                  foundAvailabilityMatchedRequisitionsFitList.includes(fit.toLowerCase() as FitStatus),
                );
              }
            } else if (searchAndSortTypes.availabilityFit.includes('NOT_FIT')) {
              return value;
            }
          });
        }

        if (searchAndSortTypes.modernHireStatus.length > 0) {
          /* eslint-disable */
          //need to return only of the condition matches
          newmappedSeekers = newmappedSeekers.filter((value) => {
            /* istanbul ignore else */
            if (value.modernHire) {
              return (
                value.modernHire?.status.toLowerCase() === searchAndSortTypes?.modernHireStatus[0] ||
                value.modernHire?.status.toLowerCase() === searchAndSortTypes?.modernHireStatus[1] ||
                value.modernHire?.status.toLowerCase() === searchAndSortTypes?.modernHireStatus[2] ||
                value.modernHire?.status.toLowerCase() === searchAndSortTypes?.modernHireStatus[3] ||
                value.modernHire?.status.toLowerCase() === searchAndSortTypes?.modernHireStatus[4]
              );
            }
          });
          /* eslint-enable */
        }
        if (
          searchAndSortTypes.availability.day ||
          searchAndSortTypes.availability.evening ||
          searchAndSortTypes.availability.night
        ) {
          /* eslint-disable */
          //need to return only of the condition matches
          newmappedSeekers = newmappedSeekers.filter((value) => {
            /* istanbul ignore else */
            if (searchAndSortTypes.availability.day) {
              return value.availability?.day === searchAndSortTypes.availability.day;
            } else if (searchAndSortTypes.availability.night) {
              return value.availability?.night === searchAndSortTypes.availability.night;
            } else if (searchAndSortTypes.availability.evening) {
              return value.availability?.evening === searchAndSortTypes.availability.evening;
            }
          });
          /* eslint-enable */
        }
        if (
          searchAndSortTypes.candidateEngagement.offerMade !== '' ||
          searchAndSortTypes.candidateEngagement.apointmentRequested !== '' ||
          searchAndSortTypes.candidateEngagement.Hired !== '' ||
          searchAndSortTypes.candidateEngagement.offerRejected !== '' ||
          searchAndSortTypes.candidateEngagement.offerAccepted !== '' ||
          searchAndSortTypes.candidateEngagement.appoinmentAccepted !== '' ||
          searchAndSortTypes.candidateEngagement.appoinmentDeclined !== '' ||
          searchAndSortTypes.candidateEngagement.appoinmentCancled !== '' ||
          searchAndSortTypes.candidateEngagement.interestRevoked !== '' ||
          searchAndSortTypes.candidateEngagement.contactRequestDeclined !== '' ||
          searchAndSortTypes.candidateEngagement.noAppointmentRequest !== ''
        ) {
          newmappedSeekers = newmappedSeekers.filter((value) => {
            if (searchAndSortTypes.candidateEngagement.noAppointmentRequest === 'NO_APPOINTMENT_REQUEST') {
              return (
                // value.computedStatus?.status.toLowerCase() === CandidateStatus.ContactRequestAccepted.toLowerCase() ||
                value.computedStatus?.status.toLowerCase() === CandidateStatus.ContactRequested.toLowerCase() ||
                value.computedStatus?.status.toLowerCase() === CandidateStatus.Bookmarked.toLowerCase() ||
                value.computedStatus?.status.toLowerCase() === CandidateStatus.Liked.toLowerCase()
              );
            }
            /* istanbul ignore next */
            return (
              value.computedStatus?.status.toLowerCase() ===
                searchAndSortTypes.candidateEngagement.apointmentRequested.toLowerCase() ||
              (value.offerStatus?.toLowerCase() === searchAndSortTypes.candidateEngagement.offerMade.toLowerCase() &&
                _isNil(value.offerAcceptanceStatus) &&
                value.computedStatus?.status.toLowerCase() === searchAndSortTypes.candidateEngagement.offerMade.toLowerCase()) ||
              value.computedStatus?.status.toLowerCase() ===
                searchAndSortTypes.candidateEngagement.contactRequestDeclined.toLowerCase() ||
              value.computedStatus?.status.toLowerCase() ===
                searchAndSortTypes.candidateEngagement.interestRevoked.toLowerCase() ||
              value.computedStatus?.status.toLowerCase() === searchAndSortTypes.candidateEngagement.Hired.toLowerCase() ||
              value.computedStatus?.status.toLowerCase() === searchAndSortTypes.candidateEngagement.offerRejected.toLowerCase() ||
              value.computedStatus?.status.toLowerCase() ===
                searchAndSortTypes.candidateEngagement.appoinmentAccepted.toLowerCase() ||
              value.computedStatus?.status.toLowerCase() ===
                searchAndSortTypes.candidateEngagement.appoinmentDeclined.toLowerCase() ||
              value.computedStatus?.status.toLowerCase() ===
                searchAndSortTypes.candidateEngagement.appoinmentCancled.toLowerCase() ||
              (searchAndSortTypes.candidateEngagement.offerAccepted !== '' &&
                value.computedStatus?.status === CandidateStatus.OfferMade &&
                value.offerStatus &&
                value.offerAcceptanceStatus?.offerAccepted === true)
            );
          });
        }

        // (searchAndSortTypes.candidateEngagement.offerAccepted !== '' &&
        //   value.computedStatus?.status.toLowerCase() ===
        //   CandidateStatus.OfferMade.toLowerCase() &&
        //   value.offerStatus &&
        //   value.offerAcceptanceStatus?.offerAccepted === true)

        if (
          searchAndSortTypes.rating[0] !== 0 ||
          searchAndSortTypes.rating[1] !== 0 ||
          searchAndSortTypes.rating[2] !== 0 ||
          searchAndSortTypes.rating[3] !== 0 ||
          searchAndSortTypes.rating[4] !== 0
        ) {
          /* eslint-disable */
          // because eslint doesnot allow for in
          newmappedSeekers = newmappedSeekers.filter((value) => {
            /* istanbul ignore else */
            if (value.recruiterInterviewReview) {
              for (const key in value.recruiterInterviewReview) {
                /* istanbul ignore else */

                if (value.recruiterInterviewReview.hasOwnProperty.call(value.recruiterInterviewReview, key)) {
                  if (searchAndSortTypes.rating.includes(parseInt(value.recruiterInterviewReview[key].rating, 10))) {
                    return value;
                  }
                }
              }
            }
          });
          /* eslint-enable */
        }

        const seekerPresenter = new SeekersPresenter(newmappedSeekers, currentUserProfile.id, minutesBeforeInactivity);

        mappedSeekers = seekerPresenter.getSeekersList(
          i18n.language,
          sort,
          searchQuery,
          group,
          searchAndSortTypes.candidateEngagement.offerRejected ||
            searchAndSortTypes.candidateEngagement.contactRequestDeclined ||
            searchAndSortTypes.candidateEngagement.interestRevoked,
        );
      }

      setSeekers(mappedSeekers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    favoriteCandidateView,
    searchAndSortTypes,
    minutesBeforeInactivity,
    seekerList,
    sort,
    searchQuery,
    group,
    currentUserProfile,
    ghostedListView,
    i18n.language,
    isFromSearchAndSort,
    archiveListView,
    dismissListView,
  ]);

  const candidate = useCandidate(candidateId);
  // istanbul ignore next
  const setAllCandidates = useStoreActions((a) => a.candidate.setAllCandidates);
  const [loading, setLoading] = useState(true);
  // istanbul ignore next
  useEffect(() => {
    if (currentUserProfile && candidate && !shouldRedirect) {
      const seekerPresenter = new SeekersPresenter([candidate], currentUserProfile.id, minutesBeforeInactivity, []);
      setSelectedEngagedCandidate(candidate);
      setAllCandidates(seekerPresenter);
      setLoading(false);
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [candidate, currentUserProfile, shouldRedirect, minutesBeforeInactivity, setAllCandidates, setSelectedEngagedCandidate]);

  useMemo(() => {
    if (
      seekerList &&
      openFilterByPositionList?.size &&
      !isPositionFilterApplied &&
      currentUserProfile &&
      isPositionSearchEnabled &&
      group === SeekerListGroupType.POSITION
    ) {
      setFilteredSeekerByPosition([]);
      let mappedSeekers = seekerList.getSeekersList(i18n.language, sort, searchQuery, group);
      let newmappedSeekers = seekerList.engagedCandidates;
      // eslint-disable-next-line consistent-return, array-callback-return
      newmappedSeekers = newmappedSeekers.filter((value) => {
        /* istanbul ignore else */
        if (openFilterByPositionList?.has(value.position)) {
          const questions = openFilterByPositionList?.get(value.position)?.question;
          if (questions) {
            /* istanbul ignore else */
            if (value.questionResponses) {
              for (let i = 0; i < value.questionResponses.length; i + 1) {
                const que = value.questionResponses[i];
                // eslint-disable-next-line no-plusplus
                for (let j = 0; j < 10; j++) {
                  const question = questions[j];
                  return question.question === que.text && question.expectedAnswer === que.answer;
                }
              }
            }
          } else {
            return value;
          }
        }
      });

      // eslint-disable-next-line consistent-return, array-callback-return
      newmappedSeekers = newmappedSeekers.filter((value) => {
        /* istanbul ignore else */

        if (openFilterByPositionList?.has(value.position)) {
          const experience = openFilterByPositionList?.get(value.position)?.experience;
          /* istanbul ignore else */
          if (experience) {
            /* istanbul ignore else */
            if (value.experience) {
              return value?.experience.totalExperience >= experience[0] && value?.experience.totalExperience <= experience[1];
            }
          } else {
            return value;
          }
        }
      });

      // eslint-disable-next-line consistent-return, array-callback-return
      newmappedSeekers = newmappedSeekers.filter((value) => {
        /* istanbul ignore else */

        if (openFilterByPositionList?.has(value.position)) {
          const salary = openFilterByPositionList?.get(value.position)?.salary;
          const salaryUnit = openFilterByPositionList?.get(value.position)?.salaryUnit;

          /* istanbul ignore else */
          if (salary && salaryUnit && value.preferredSalary && value.preferredSalary.unit === salaryUnit) {
            return value.preferredSalary.value >= salary[0] && value.preferredSalary.value <= salary[1];
          }
          return value;
        }
      });
      const seekerPresenter = new SeekersPresenter(newmappedSeekers, currentUserProfile.id, minutesBeforeInactivity);
      mappedSeekers = seekerPresenter.getSeekersList(i18n.language, sort, '', SeekerListGroupType.NONE);
      const filteredCandaidates = _map(mappedSeekers, 'item.candidate.id');

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      setFilteredSeekerByPosition(filteredCandaidates);
      /* istanbul ignore else */
      if (!selectedGroup?.isOpen) selectedGroup?.toggleNode?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPositionFilterApplied]);

  useEffect(() => {
    /* istanbul ignore next */
    if (isPositionCandidatesPage()) {
      if (!allPositionSeekerList || !allPositionSeekerList.engagedCandidates) return;

      if (!selectedEngagedCandidate || !allPositionSeekerList.engagedCandidates.includes(selectedEngagedCandidate)) {
        selectedCallback(allPositionSeekerList.getFirstEngagedCandidate(candidateId));
      }
    } else {
      if (!seekerList || !seekerList.engagedCandidates) return;

      if (
        !selectedEngagedSeeker &&
        (!selectedEngagedCandidate || !seekerList.engagedCandidates.includes(selectedEngagedCandidate)) &&
        shouldRedirect
      ) {
        selectedCallback(seekerList.getFirstEngagedCandidate(candidateId));
      }
    }
  }, [
    selectedEngagedSeeker,
    selectedEngagedCandidate,
    candidateId,
    selectedCallback,
    seekerList,
    shouldRedirect,
    allPositionSeekerList,
  ]);

  const isSeekerSelected = (item: TreeMenuItem): boolean => {
    if (!selectedEngagedSeeker || !group) {
      return false;
    }
    if (group === SeekerListGroupType.NONE) {
      return selectedEngagedSeeker.seekerId === (item.item as CandidateItemNode).candidate.seeker;
    }
    return selectedEngagedSeeker.candidateId === (item.item as CandidateItemNode).candidate.id;
  };

  const handleScroll = (event: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const top = event.target.scrollTop === 0;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const bottom = event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight < 1;

    if (bottom) {
      if (visibleSeekerCount < getConfig().maxVisibleSeekerCount) {
        setVisibleSeekerCount(visibleSeekerCount + getConfig().visibleSeekerCountIncrement);
      }
    }

    if (top) {
      setVisibleSeekerCount(getConfig().defaultVisibleSeekerCount);
    }
  };

  const handlePositionWiseSearch = (candidateItem: ParentItemNode) => {
    const { en } = candidateItem.title as LocalizedString;
    const selectedSeekerPosition = seekerList?.engagedCandidates.filter((value) => {
      return value?.jobTitle?.en === en;
    });
    /* istanbul ignore next */
    setSelectedPositionIdForFilter(selectedSeekerPosition?.length ? selectedSeekerPosition[0].position : '');

    setIsPositionFilterApplied(true);
  };
  /* istanbul ignore next */
  const isFilterApplied = (candidateItem: ParentItemNode) => {
    const { en } = candidateItem.title as LocalizedString;
    const selectedSeekerPosition = seekerList?.engagedCandidates.filter((value) => {
      return value.jobTitle.en === en;
    });

    return openFilterByPositionList?.has(selectedSeekerPosition?.length ? selectedSeekerPosition[0].position : '');
  };
  /* istanbul ignore next */
  const isFilterAppliedChild = (candidateItem: TreeMenuItem) => {
    const selectedSeekerPosition = seekerList?.engagedCandidates.filter((value) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return value.businessName === candidateItem.item.title && value.jobTitle.en === candidateItem.parent;
    });
    return openFilterByPositionList?.has(selectedSeekerPosition?.length ? selectedSeekerPosition[0].position : '');
  };

  const getNumberOfFilters = (candidateItem: ParentItemNode) => {
    let count = 0;
    const { en } = candidateItem.title as LocalizedString;
    const selectedSeekerPosition = seekerList?.engagedCandidates.filter((value) => {
      return value.jobTitle.en === en;
    });

    if (
      openFilterByPositionList?.has(
        selectedSeekerPosition?.length ? selectedSeekerPosition[0].position : /* istanbul ignore next */ '',
      )
    ) {
      const filters = openFilterByPositionList?.get(
        selectedSeekerPosition?.length ? selectedSeekerPosition[0].position : /* istanbul ignore next */ '',
      );

      if (filters?.question) {
        count += 1;
      }
      if (filters?.salary) {
        count += 1;
      }
      if (filters?.experience) {
        count += 1;
      }
    }
    return count;
  };

  // todo check hereeee
  useEffect(() => {
    if (changeCandidate) {
      if (CandidateListType.DISMISS_CANDIDATE_LIST === listType) {
        if (seekerList?.dismissedCandidate && seekerList?.dismissedCandidate.length > 0) {
          const nonBlacklistedCandidates = seekerList?.dismissedCandidate.filter((value) => !value.blacklisted);

          setSelectedEngagedCandidate(nonBlacklistedCandidates[0]);
          setSelectedEngagedSeeker(
            nonBlacklistedCandidates[0] && {
              seekerId: nonBlacklistedCandidates[0].seeker,
              candidateId: nonBlacklistedCandidates[0].id,
            },
          );
          // istanbul ignore else
          if (!isPositionCandidatesPage()) {
            if (shouldRedirect) {
              window.history.pushState(
                null,
                '',
                MESSAGING_TABS(seekerList?.dismissedCandidate[0].id, SeekerContainerTabItems.Messaging),
              );
            } else {
              window.history.pushState(
                null,
                '',
                MESSAGING_CANDIDATE_TABS(seekerList?.dismissedCandidate[0].id, SeekerContainerTabItems.Messaging),
              );
            }
          }
        }
      } else if (CandidateListType.ARCHIVED_CANDIDATE_LIST === listType) {
        const newSeeker = seekerList?.engagedCandidates.filter(
          (seeker: EngagedCandidate) =>
            seeker.computedStatus?.category === CandidateCategory.Other &&
            seeker.computedStatus.status !== CandidateStatus.Dismissed,
        );

        if (newSeeker && newSeeker.length > 0) {
          setSelectedEngagedCandidate(newSeeker[0]);
          setSelectedEngagedSeeker(newSeeker[0] && { seekerId: newSeeker[0].seeker, candidateId: newSeeker[0].id });
          // istanbul ignore else
          if (!isPositionCandidatesPage()) {
            window.history.pushState(
              null,
              '',
              shouldRedirect
                ? MESSAGING_TABS(newSeeker[0].id, SeekerContainerTabItems.Messaging)
                : MESSAGING_CANDIDATE_TABS(newSeeker[0].id, SeekerContainerTabItems.Messaging),
            );
          }
        }
      } else if (CandidateListType.GHOSTED_CANDIDATE_LIST === listType) {
        const newSeeker = seekerList?.engagedCandidates.filter(
          (seeker: EngagedCandidate) => seeker.ghostingStatus?.status === 'GHOSTED',
        );

        if (newSeeker && newSeeker.length > 0) {
          setSelectedEngagedCandidate(newSeeker[0]);
          setSelectedEngagedSeeker(newSeeker[0] && { seekerId: newSeeker[0].seeker, candidateId: newSeeker[0].id });
          // istanbul ignore else
          if (!isPositionCandidatesPage()) {
            window.history.pushState(
              null,
              '',
              shouldRedirect
                ? MESSAGING_TABS(newSeeker[0].id, SeekerContainerTabItems.Messaging)
                : MESSAGING_CANDIDATE_TABS(newSeeker[0].id, SeekerContainerTabItems.Messaging),
            );
          }
        }
      } else if (CandidateListType.FAVOURITE_CANDIDATE_LIST === listType) {
        const newSeeker = seekerList?.engagedCandidates.filter(
          (seeker: EngagedCandidate) =>
            user?.favouriteSeekers?.some((item: FavouriteSeeker) => item.id === seeker.seeker) &&
            seeker.ghostingStatus?.status !== 'GHOSTED' &&
            seeker.computedStatus?.category !== CandidateCategory.Other,
        );

        if (newSeeker && newSeeker.length > 0) {
          setSelectedEngagedCandidate(newSeeker[0]);
          setSelectedEngagedSeeker(newSeeker[0] && { seekerId: newSeeker[0].seeker, candidateId: newSeeker[0].id });
          // istanbul ignore else
          if (!isPositionCandidatesPage()) {
            window.history.pushState(
              null,
              '',
              shouldRedirect
                ? MESSAGING_TABS(newSeeker[0].id, SeekerContainerTabItems.Messaging)
                : MESSAGING_CANDIDATE_TABS(newSeeker[0].id, SeekerContainerTabItems.Messaging),
            );
          }
        }
      } else if (CandidateListType.NONE === listType) {
        const newSeeker = seekerList?.engagedCandidates.filter(
          (seeker: EngagedCandidate) =>
            seeker.computedStatus?.category !== CandidateCategory.Other && seeker.ghostingStatus?.status !== 'GHOSTED',
        );
        if (newSeeker && newSeeker.length > 0) {
          setSelectedEngagedCandidate(newSeeker[0]);
          setSelectedEngagedSeeker(newSeeker[0] && { seekerId: newSeeker[0].seeker, candidateId: newSeeker[0].id });
          // istanbul ignore else
          if (!isPositionCandidatesPage()) {
            window.history.pushState(
              null,
              '',
              shouldRedirect
                ? MESSAGING_TABS(newSeeker[0].id, SeekerContainerTabItems.Messaging)
                : MESSAGING_CANDIDATE_TABS(newSeeker[0].id, SeekerContainerTabItems.Messaging),
            );
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listType]);

  if (!shouldRedirect && loading) {
    return (
      <Box className="super-centered" height="80vh">
        <Stack direction="column" alignItems="center" spacing={4} data-testid="Loading">
          <Spinner color="#1F3CBA" data-testid="Spinner" />
          <Box>{t('app:loading')}</Box>
        </Stack>
      </Box>
    );
  }
  // istanbul ignore else
  if (shouldRedirect) {
    return (
      <Box data-testid="SeekerListContainer" css={seekersListContainer} d={showSidebar ? 'contents' : 'none'}>
        {shouldRedirect && (
          <Box py={/* istanbul ignore next */ isCertnFeatureEnabled && isCertnEnabled ? 3 : ''} px={3} css={seekersListFilters}>
            <SeekerListFilters
              isCertnFeatureEnabled={isCertnFeatureEnabled}
              isCertnEnabled={isCertnEnabled}
              isUnresponsiveCandidatesEnabled={isUnresponsiveCandidatesEnabled}
              isArchiveListEnabled={isArchiveListEnabled}
              isDismissedCandidateEnabled={isDismissedCandidateEnabled}
              onOpenSearch={onOpenSearch}
              showSearchSort={showSearchSort}
            />
          </Box>
        )}
        {isPositionFilterApplied && (
          <PositionWiseSearchModal
            onClose={
              /* istanbul ignore next */ () => {
                setIsPositionFilterApplied(false);
                setSelectedGroup(undefined);
              }
            }
          />
        )}

        <MotionBox data-testid="SidebarView" css={seekersListItems} onScroll={handleScroll}>
          {!seekerList && (
            <Box height="80%" className="noCandidateMsg">
              <Center>
                <Spinner color={colors.blue.default} data-testid="Spinner" />
              </Center>
            </Box>
          )}
          {
            /* istanbul ignore next */ seekerList && seekers.length === 0 && !shouldRedirect && (
              <Box height="80%" className="noCandidateMsg">
                <Box>
                  <Flex direction="column" data-testid="NoSearchCandidates" align="center">
                    <Image src="/search.svg" w="108px" h="76px" alt="No Search Candidates" />
                    <Text color="#90A4AE" fontSize="14px" mt="5">
                      <b>{t('searchResultMsg')}</b>
                    </Text>
                  </Flex>
                </Box>
              </Box>
            )
          }
          {seekerList && seekers.length > 0 && (
            <TreeMenu data={seekers} resetOpenNodesOnDataUpdate disableKeyboard cacheSearch={false}>
              {({ items }) => (
                <AnimatePresence initial={false}>
                  {_take(items, visibleSeekerCount).map((item) => (
                    <motion.div
                      layout
                      key={item.key}
                      id={item.key}
                      initial={{ opacity: 1 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 1 }}
                      transition={{ duration: 0.2, ease: 'linear' }}
                    >
                      <Box className={addBoxClass(item)} data-testid="SeekerListItem">
                        {item.hasNodes && (
                          <Box
                            data-testid="SeekerToggleIcon"
                            className="candidate-bar"
                            id="test"
                            onClick={(e) => {
                              item.toggleNode?.();
                              e.stopPropagation();
                            }}
                          >
                            <ToggleIcon on={item.isOpen} />
                            {item.type === NodeType.PARENT && (
                              <Flex>
                                <ParentItem
                                  item={item.item as ParentItemNode}
                                  isFilterApplied={isFilterApplied(item.item as ParentItemNode)}
                                />
                                <Spacer />
                                {group === SeekerListGroupType.POSITION &&
                                  isPositionSearchEnabled &&
                                  !archiveListView &&
                                  !favoriteCandidateView &&
                                  !dismissListView &&
                                  !ghostedListView && (
                                    <Tooltip hasArrow label={t('positionWiseSearch')}>
                                      <Box
                                        position="relative"
                                        size="xs"
                                        data-testid="filterIcon"
                                        mr={3}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setSelectedGroup(item);
                                          handlePositionWiseSearch(item.item as ParentItemNode);
                                        }}
                                      >
                                        <IconButton
                                          fontSize="16px"
                                          colorScheme="blue"
                                          style={{
                                            borderRadius: '50%',
                                            marginTop: '-4px',
                                            background: isFilterApplied(item.item as ParentItemNode) ? '#1F3CBA' : '#E7E7E7',
                                            color: isFilterApplied(item.item as ParentItemNode) ? '#ffffff' : '#000000',
                                          }}
                                          aria-label="Search And Sort"
                                          height="30px"
                                          width="30px"
                                          minWidth="30px"
                                          icon={<BsFunnel />}
                                          mr={3}
                                        />
                                        {getNumberOfFilters(item.item as ParentItemNode) !== 0 && (
                                          <span
                                            style={{
                                              background: colors.blue[500],
                                              color: colors.white,
                                              boxShadow: `0px 1px 1px ${colors.white}`,
                                              height: '18px',
                                              width: '18px',
                                              display: 'inline-block',
                                              textAlign: 'center',
                                              lineHeight: '1.3',
                                              borderRadius: '50%',
                                              position: 'absolute',
                                              top: '-9px',
                                              right: '1px',
                                              fontSize: '14px',
                                            }}
                                          >
                                            {getNumberOfFilters(item.item as ParentItemNode)}
                                          </span>
                                        )}
                                      </Box>
                                    </Tooltip>
                                  )}
                              </Flex>
                            )}
                            {item.type === NodeType.CHILD && (
                              <ChildItem item={item.item as ParentItemNode} isFilterApplied={isFilterAppliedChild(item)} />
                            )}
                          </Box>
                        )}

                        {item.type === NodeType.CANDIDATE && shouldRedirect && (
                          <CandidateItem
                            item={item.item as CandidateItemNode}
                            selected={selectedEngagedSeeker}
                            onSelected={selectedCallback}
                            activeClass={/* istanbul ignore next */ isSeekerSelected(item) ? 'active-list' : 'list-item'}
                            isShowFavoriteStatusEnabled={isShowFavoriteStatusEnabled}
                            filteredSeekerByPosition={filteredSeekerByPosition}
                            seekerList={seekerList}
                          />
                        )}
                      </Box>
                    </motion.div>
                  ))}
                </AnimatePresence>
              )}
            </TreeMenu>
          )}
        </MotionBox>
      </Box>
    );
  }
  // istanbul ignore next
  if (!candidate) {
    return <NotAuthorizedView isAuthorizationStateLoaded />;
  }
  // istanbul ignore next
  return <Box />;
};
